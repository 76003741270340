'use client'

import { useEffect, useState } from 'react'

// Returns true if the screen width is below the given pixel value
export function useIsScreenWidthBelow(px: number) {
    const [isBelow, setIsBelow] = useState(false)

    useEffect(() => {
        const checkIsMobile = () => setIsBelow(window.matchMedia(`(max-width: ${px}px)`).matches)

        checkIsMobile()
        window.addEventListener('resize', checkIsMobile)

        return () => window.removeEventListener('resize', checkIsMobile)
    }, [])

    return isBelow
}
