'use client'

import Image from 'next/image'
import { Heading, LandingSection } from './LandingComponents'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

interface Testimonial {
    text: string
    nameAndProfession: string
    companyAndCity: string
    imageSrc: string
}

const testimonials: Testimonial[] = [
    {
        text: 'Bevor ich Tebuto genutzt habe, hat mich das Termin-Management täglich bis zu 60 Minuten gekostet. Jetzt benötige ich dafür wöchentlich 15 Minuten und kann die frei gewordene Zeit für mich und meine Klient:innen nutzen.',
        nameAndProfession: 'Sarah Scholl, Psychologin M. Sc.',
        companyAndCity: 'Richtungswechsler, Karlsruhe',
        imageSrc: '/assets/sarah.webp'
    }
]

export default function SocialProof() {
    return (
        <LandingSection>
            <div className="text-center">
                <p className="text-landing">Die Ergebnisse sprechen für sich</p>
                <Heading>Was unsere Kunden sagen</Heading>
            </div>
            <Carousel
                ssr
                swipeable
                draggable
                pauseOnHover
                arrows={false}
                className="pb-24 pt-32"
                showDots={testimonials.length > 1}
                customTransition="transform 1000ms ease-in-out"
                rewind
                rewindWithAnimation
                responsive={{
                    any: {
                        breakpoint: { max: 4000, min: 0 },
                        items: 1
                    }
                }}
            >
                {testimonials.map((testimonial, index) => (
                    <Testimonial testimonial={testimonial} key={index} />
                ))}
            </Carousel>
        </LandingSection>
    )
}

function Testimonial({ testimonial }: { testimonial: Testimonial }) {
    return (
        <div className="flex max-md:flex-col gap-20 items-center w-full">
            {/* Image */}
            <div className="flex-shrink-0 flex-grow-0 lg:w-96 min-[300px]:w-64 min-[300px]:h-64 flex justify-center max-md:w-96 max-w-full">
                <Image src={testimonial.imageSrc} width={400} height={400} alt={testimonial.nameAndProfession} className="rounded-full h-full w-auto" />
            </div>
            {/* Content */}
            <div className="w-full relative">
                <Image
                    src="/assets/testimonial-graphic.svg"
                    alt={'Testimonial Quotation Marks'}
                    height={250}
                    width={250}
                    className="absolute md:-left-16 md:-top-16 -top-8 left-0"
                />
                <div>
                    <p className="text-landing font-medium text-gray-800">{testimonial.text}</p>
                </div>
                <div className="mt-10">
                    <p className="text-landing font-bold text-gray-800">{testimonial.nameAndProfession}</p>
                    <p className="text-xl font-medium tracking-tight text-gray-600">{testimonial.companyAndCity}</p>
                </div>
            </div>
        </div>
    )
}
