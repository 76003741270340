import { ApiError } from '@tebuto/api/public'
import { ContactFormPayload } from '@tebuto/api/public/models/ContactFormPayload'
import { ERROR_MESSAGES, resolveErrorMessage, usePublicApiMutation } from '@tebuto/functions'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

export function useSendContactForm(setError: Dispatch<SetStateAction<string | null>>) {
    const { trigger } = usePublicApiMutation('sendContactForm', (api, values: ContactFormPayload) => api.contact.sendContactRequest(values), {
        onError: error => {
            let errorMessage = ERROR_MESSAGES.Unknown
            if (error instanceof ApiError) {
                errorMessage = resolveErrorMessage(error.body.type)
            }
            setError(errorMessage)
        }
    })
    return trigger
}

export function useEasterEgg(pattern: string, timeRangeSeconds = 1) {
    const [easterEggEnabled, setEasterEggEnabled] = useState(false)
    const [input, setInput] = useState<string[]>([])

    useEffect(() => {
        const onKeyDown = (e: KeyboardEvent) => {
            setInput(prev => [...prev, e.key].slice(-3))
        }

        window.addEventListener('keydown', onKeyDown)

        return () => {
            window.removeEventListener('keydown', onKeyDown)
        }
    }, [])

    useEffect(() => {
        if (input.join('') === pattern) {
            setEasterEggEnabled(prev => !prev)
        }

        if (input.length > 0) {
            setTimeout(() => {
                setInput([])
            }, timeRangeSeconds * 1000)
        }
    }, [input])

    return { easterEggEnabled }
}
