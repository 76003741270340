'use client'

import { useRouter } from 'next/navigation'
import { getRegistrationUrl } from './registrieren/server-actions'

interface GetFreeTrialButtonProps {
    className?: string
}

export function GetFreeTrialButton({ className }: GetFreeTrialButtonProps) {
    const router = useRouter()

    async function goToRegistration() {
        const registrationUrl = await getRegistrationUrl()
        router.push(registrationUrl)
    }

    return (
        <button type="button" className={className} onClick={goToRegistration}>
            Jetzt 30 Tage kostenlos testen
        </button>
    )
}
