'use client'

import { ChevronUpIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'

export default function ScrollToTop() {
    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.scrollY > window.innerHeight) {
                setIsVisible(true)
            } else {
                setIsVisible(false)
            }
        }

        window.addEventListener('scroll', toggleVisibility)

        return () => {
            window.removeEventListener('scroll', toggleVisibility)
        }
    }, [])

    const scrollToTop = () => {
        const topElement = document.getElementById('top')
        if (topElement) {
            topElement.scrollIntoView({ behavior: 'smooth' })
        }
    }

    return (
        <>
            {isVisible && (
                <button
                    type="button"
                    onClick={scrollToTop}
                    className="fixed bottom-4 right-4 bg-primary-600 text-white p-3 rounded-full shadow-lg hover:bg-primary-500 focus:outline-none"
                    aria-label="Nach oben scrollen"
                >
                    <ChevronUpIcon className="w-6 h-6" />
                </button>
            )}
        </>
    )
}
